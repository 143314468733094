import styled from 'styled-components';

export const ModalContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 450px) {
      flex-direction: column;
      gap:20px;   
    
      };

    
    .card{
        border:none;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #333333;
            
        .title{
            font-weight: 700;
            font-size: 40px;

        }
        .dis{
            font-size:20px;
            margin-left: 20px;
            @media screen and (max-width: 450px) {
            width:100%;
      };
    

        }
        .exit{
            position: absolute;
            top:10px;
            right: 10px;
            font-size: 20px;
            cursor: pointer;
            @media screen and (max-width: 450px) {
                left: 50%;
                top: 200%; ;
                
        
      };

        }
    }

`;


export const Video = styled.video`

    width:80%;
    @media screen and (max-width: 450px) {
        width:100%;
      };
    



`;





    

