import { Fragment } from 'react';
import MainContainer from './components/MainContainer/MainContainer';
import SideBar from './components/SideBar/SideBar';
import {GlobalStyle} from './global.style';
import SecondaryContainer from './components/SecondaryContainer/SecondaryContainer'
import AboutMe from './components/sections/AboutMe/AboutMe';
import Skills from './components/sections/Skills/Skills';
import MyWork from './components/sections/MyWork/MyWork';
import ContactMe from './components/sections/ContactMe/ContactMe';


function App() {
  return (
    <Fragment>
      <GlobalStyle />
      
      <MainContainer>
        <SideBar />
        <SecondaryContainer>
          <AboutMe/>
          <Skills />
          <MyWork/>
          <ContactMe/>        

        </SecondaryContainer>


      </MainContainer>

       
    </Fragment>
  );
};



export default App;

