import styled,{createGlobalStyle} from 'styled-components';
import page_bg from './assets/images/page_bg.jpg';

export const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding:0px ;
        background-image: url(${page_bg});
        background-position: center center;
        background-size:cover;
        background-attachment:fixed;
        font-family: 'Poppins', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow-x: hidden;

    }
    a {
        text-decoration: none;
        
    }
    * {
        box-sizing: border-box;
    }


      
`;

// title for sections

export const Title = styled.div`
    text-align: center;
    padding: 5%;
    .line{
        width: 60px;
        height: 3px;
        background-color: tomato;
        margin: 10px auto;
        @media screen and (max-width: 450px) {       
            margin: 10px 0;           
        };
    };          
    h2 {
        font-size: 35px;
        font-weight: 700;
        color: #fff;
        @media screen and (max-width: 450px) {       
            font-size: 20px;           
        };
    };

    @media screen and (max-width: 450px) {
        margin-top: 20%;
        text-align: left;
        width: 250px;
        p {
            font-size: 15px;        
        };       

     };

`;


