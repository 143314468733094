import styled from "styled-components";

export const ProjectsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    gap:10px;
    margin-top:50px;
    transition: all 0.5s; 
    @media screen and (max-width: 1080px) {
      gap:1px;    
      justify-content: space-between;  
      };
    @media screen and (max-width: 450px) {
      flex-direction: column;   
      };
`;

