import styled from "styled-components";

export const ProjectContainer = styled.div`
    position: relative;
    width: 24%;
    @media screen and (max-width: 1080px) {
        width: 290px;
      };  
    @media screen and (max-width: 900px) {
        width: 250px;
      };   
    @media screen and (max-width: 450px) {
        width: 245px;
      };
      
    .icon{
        display: inline-block;
        color:#333333;
        font-size: 30px;
        cursor: pointer;
    };

    img{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    opacity:.8 ;
    };

    figure {
        position: relative;
    overflow: hidden;
    perspective: 50em;
    -webkit-perspective: 50em;
    -moz-perspective: 50em;
    -ms-perspective: 50em;
    -o-perspective: 50em;
    };

    * {
     box-sizing: padding-box;   
    -webkit-box-sizing: padding-box;
     transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    };

    figcaption {
    top: 50%;
    left: 20px;
    right: 20px;
    position: absolute;
    opacity: 0;
    z-index: 1;
    text-align: center;
    };

    h6 {
    margin-top: 15px;
    margin-bottom: 5px;
    color: #232323;
    
    };

   span {
        font-size: 14px;
        font-style: italic;
        display: block;
        color: #7a7a7a;
    };
    
    figure::after {
        content:'';
        background-color:rgb(238 238 238 / 95%);
        position: absolute;
        display: block;
        top: 20px;
        left: 20px;
        right: 20px;
        bottom: 20px;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        -webkit-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        opacity: 0;
    };

    figure:hover figcaption {
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        opacity: 1;  
        transition-delay: 0.2s;
    };
    figure:hover:after {
        transform: rotateX(0);
        -webkit-transform: rotateX(0);
        -moz-transform: rotateX(0);
        -ms-transform: rotateX(0);
        -o-transform: rotateX(0);
         opacity: 0.9;
    };

    .digure:hover figcaption {

        transform: translate(-50%);
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -ms-transform: translate(-50%);
        -o-transform: translate(-50%);
  
    };




`;
