import SectionsContainer from "../SectionsContainer/SectionsContainer";
import { Title } from "../../../global.style";
import { ProjectsContainer } from "./myWork.style";

import WorkItem from "./WorkItem/WorkItem";

const projectData = [
    {
      id:1,
      title: 'Time Out',
      urlVideo: "./videos/timeout.mp4",
      img: "./images/timeout.jpg",
      discription:'C4d & Illustrator & Photoshop & After Effects'
    }
    ,
    {
      id:2,
      title: 'One Of Three',
      urlVideo: "./videos/one_of_three.mp4",
      img: "./images/one_of_three.jpg",
      discription:'C4d & Illustrator & Photoshop & After Effects'
    }
    ,
    {
      id:3,
      title: 'Today,s Press',    
      urlVideo: "./videos/alsahafa_alyaum.mp4",
      img: "./images/today_s_press.jpg",
      discription:'Photoshop & Illustrator & ViZrt'
    }  
    ,
    {
      id:4,
      title: 'Analysis Studio',
      urlVideo: "./videos/studio_tahleel.mp4",
      img: "./images/analysis_studio.jpg",
      discription:'Photoshop & ViZrt'
    }
    ,
    {
      id:5,
      title: 'Prayer Time',
      urlVideo: "./videos/praytime.mp4",
      img: "./images/praytime.jpg",
      discription:'Photoshop & ViZrt'
    }
    ,
    {
      id:6,
      title: 'Women,s Sport',
      urlVideo: "./videos/sport_almaraa.mp4",
      img: "./images/women_s_sport.jpg",
      discription:'Photoshop & Illustrator & ViZrt'
    }
];

const MyWork = () => {

   return (
    <SectionsContainer>
      <Title>
        <h2>My Work</h2>
        <div className='line'></div>
        <p>Some projects that I have created.</p>
      </Title>  
      <ProjectsContainer>
          {projectData &&
          projectData.map((project) => (
            <WorkItem key={project.id} project={project} />
          ))}

      </ProjectsContainer>
      



    </SectionsContainer>
  )
}

export default MyWork;

