import styled from "styled-components";

export const CardsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    gap:10px;
    margin-top:50px;
    transition: all 0.5s;  
    @media screen and (max-width: 450px) {
        flex-direction: column;        
        width:245px;       
    };  
`;
export const CardContainer = styled.div`
    width: 49%;
    min-height:350px;
    display:flex;
    flex-direction: column;
    background-color: rgba(250, 250, 250, 0.1);
    padding:40px;
    transition: all 0.5s;
    @media screen and (max-width: 450px) {
        min-height:150px;
        width: 245px;
        padding:20px;
        align-items:center;
    };  

    .icon{
        color:cyan;
        font-size: 70px;
        @media screen and (max-width: 450px) {
            font-size: 40px;

    }; 
    };
    .card_title{
            color:#fff;
        font-size: 30px;
        font-weight: 700;
        padding:15px 0;
        @media screen and (max-width: 450px) {
            font-size: 20px;

    }; 
        };

    .discription{
            color:#fff;
           };

    :hover {
            background-color:rgb(238 238 238 / 95%);
            .icon {
                color: #4a4a4a;} ;
            .card_title {
                color: #1e1e1e;
                };    
            .discription{
                color: #4a4a4a;}         
            };   
`;





