import styled from "styled-components";

export const ContactMeContainer = styled.div`

input  {
    background-color: rgba(250 250 250 / 5%);
    border: 1px solid rgba(250,250,250,1);

    margin-bottom: 30px;
    color: #EEEEEE;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    width:49%;
    padding-left: 15px;
    @media screen and (max-width: 450px) {
        width: 245px;
      };
};
.inputContainer{
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 450px) {
      flex-direction: column;
      /* gap:20px;    */
    
      };
}

textarea {
    background-color: rgba(250 250 250 / 5%);
    border: 1px solid rgba(250,250,250,1);
    margin-bottom: 30px;
    color:  #EEEEEE;
    font-size: 14px;
    height: 150px;
    max-height: 220px;
    line-height: 40px;
    width: 100%;
    padding-left: 15px;
    @media screen and (max-width: 450px) {
        width: 245px;
        height: 100px;

      };
}

button {
    background-color: rgba(250 250 250 / 5%);
    border: 1px solid rgba(250,250,250,1);
    color:  #EEEEEE;
}

`;