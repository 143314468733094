import {ProjectContainer}from './workItem.style';
import ModalOpen from '../../../modal/modal.component';
import {useState}from 'react';
import {BsPlayCircle} from 'react-icons/bs';

const WorkItem = ({project}) => {
    const {img,title,discription,urlVideo} = project;

    const[modalIsOpen,setModalIsOpen]=useState(false);


  return (
    <ProjectContainer>              
    <figure>                  
      <img src={img} alt="My Work" /> 
      <figcaption>
        <div className="icon" > <BsPlayCircle onClick ={()=> {setModalIsOpen(true) }}  /> </div>                                                         
        <h6> {title} </h6>
      </figcaption>   
      <ModalOpen modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} title={title} discription={discription} url={urlVideo} />               
    </figure>   
    
</ProjectContainer>    
  )
}

export default WorkItem;