import Modal from 'react-modal';
import {ModalContainer,Video} from './modal.styles';
import {BsXLg} from 'react-icons/bs';


const ModalOpen = ({modalIsOpen,setModalIsOpen,discription,title,url}) => {

   return <Modal isOpen = {modalIsOpen} ariaHideApp={false}  onRequestClose ={()=> setModalIsOpen(false)}>
                <ModalContainer>

                    <Video src= {url} controls / >  
                  
                    <div className='card'>
                        <BsXLg onClick={()=> setModalIsOpen(false)} className='exit' />
                        <p className='title'> {title} </p>
                        <p className='dis'> {discription} </p>
                    </div>                 
                    
                    
                </ModalContainer>
               
          </Modal>
}

export default ModalOpen;