import { SectionStyleContainer } from "./sectionsContainer.style";


const SectionsContainer = ({children}) => {
  return (

    <SectionStyleContainer>
       {children} 
    <div className="bar"></div>
    </SectionStyleContainer> 
   
         
  )
}

export default SectionsContainer;