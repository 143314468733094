import SectionsContainer from '../SectionsContainer/SectionsContainer';
import {AboutMeSection} from './aboutMe.style';
import { Title } from "../../../global.style";


const AboutMe = () => {
  return (
    <SectionsContainer>
       
        <Title>
            <h2>About Me</h2>
            <div className='line'></div>
            <p>I'm a frontend developer and motion graphic designer. My focus now is on creative coding for modern web applications.
                I enjoy working with frontend technologies, building a good user experience and paying great attention to details.
            </p>
        </Title>

        <AboutMeSection>
            <div> 
                <img className='left-image' src="./images/left-image.jpg" alt="" />
            </div>
            <div className='text'>
                <h3>
                 Frontend developer      
                </h3>
                <p>
                 HTML, Css, Sass, JavaScript, React, bootstrap Git, GitHub, Firebase, MongoDB
                </p>
                {/* <span>putton</span> */}
            </div>
        </AboutMeSection>
        <div className="bar"></div>
        <AboutMeSection>
            <div className='text'>
                <h3>
                 Graphic designer 
                </h3>
                <p>
                 Maxon C4D, Vizrt,
                 After Effects, Photoshop,
                 Illustrator, Audition
                </p>
                {/* <span>putton</span> */}
            </div>
            <div> 
                <img className='right-image' src="./images/right-image.jpg" alt="" />
            </div>

        </AboutMeSection>
        
        
    </SectionsContainer>
  )
}

export default AboutMe;