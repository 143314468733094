import SectionsContainer from '../SectionsContainer/SectionsContainer';
import { Title } from "../../../global.style";
import {ContactMeContainer} from './contactMe.style.js';
// import { Button } from '../../button/button.component';
import { db } from '../../../firebase';
import { useState } from 'react';
import {addDoc,collection} from 'firebase/firestore';


const ContactMe = () => {
  const [name,setName]=useState('');
  const [email,setEmail]=useState('');
  const [message,setMessage]=useState('');
  const userCollectionRef = collection(db,"conatctData");

  const handleSubmit = (e) => {
    e.preventDefault();
    if(name==='' || email===''|| message===''){
     alert("Please fill in all fields") ;
     return;
    } else
    {
      addDoc(userCollectionRef,
      {
        name: name,
        email: email,
        message: message
      })     
      .then(
      ()=> {
        alert("Message has been submitted");
      }     
    );}   ;

    setName('');
    setEmail('');
    setMessage('');

};
  return (
    <SectionsContainer>
      <Title>
        <h2>Contact Me</h2>
        <div className='line'></div>
        <p>contact@mazenaldebeat.com</p>
      </Title>
      <ContactMeContainer>
        <form onSubmit={handleSubmit}>
          <div className='inputContainer'>
            <input
              value={name}
              placeholder='Name'
              onChange={(e)=> setName(e.target.value)}
              required
                />

            <input
              value={email}
              placeholder='Email'
              onChange={(e)=> setEmail(e.target.value)} 
              required
              />        
          </div>
           <textarea
           value={message}
           placeholder='Message'
           onChange={(e)=> setMessage(e.target.value)}
           required

           ></textarea>
           <button type='supmit' onClick={handleSubmit}>Send Message</button>
        </form>

      </ContactMeContainer>  

    </SectionsContainer>
 
)}

export default ContactMe;