import styled from "styled-components";

export const SecondaryContainerStyle = styled.div`
    display: flex;
    flex-direction:column;
    width:55%;
    margin-left:36%;
    @media screen and (max-width: 1080px) {
    margin-left:400px;
    width:600px;
    };
    @media screen and (max-width: 900px) {    
        margin-left:265px;
        width:520px;
    };
    @media screen and (max-width: 450px) {    
        margin-left:160px;
        width:260px;    
        };
`;