import SectionsContainer from "../SectionsContainer/SectionsContainer"
import {CardContainer,CardsContainer } from "./skills.style";
import {BsLightbulb,BsCodeSlash,BsGear,BsGift} from 'react-icons/bs';
import { Title } from "../../../global.style";

const cardData = [
  {
    id:1,
    icon:BsCodeSlash ,
    title: 'HTML5 & CSS3',
    discription:'Handling HTML codes via JSX and CSS via Styled Component.'
  },
  {
    id:2,
    icon: BsLightbulb,
    title: 'Creative Ideas',
    discription:'The ability to make smart decisions with any React project.'
  },
  {
    id:3,
    icon: BsGear,
    title: 'Easy Customize',
    discription:'With my experience as a graphic designer, I can professionally solve most of the problems I encounter.'
  },
  {
    id:4,
    icon: BsGift,
    title: 'Admin Dashboard',
    discription:'Professional handling of GitHub and databases.'
  }
  
];

const Skills = () => {
  return (
    <SectionsContainer>
        <Title>
          <h2>What I’m good at?</h2>
          <div className='line'></div>
          <p>
          Full understanding of project requirements at different stages of production and solving the problems.</p>
        </Title>
        <CardsContainer>

            { cardData && 
                cardData.filter((_, idx) => idx <4).map( (card) => 

                <CardContainer key={card.id}>
                              
                  <div className="icon" > <card.icon/>  </div>
                  <p className="card_title"> {card.title} </p>
                  <span className="discription"> {card.discription} </span>
                                              
                </CardContainer>
          )
          }
       
        </CardsContainer>
    </SectionsContainer>
  )
}

export default Skills;
