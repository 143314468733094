import styled from "styled-components";

export const AboutMeSection = styled.div`
   display: flex;
   justify-content: space-between;  

   .left-image{
         border-radius:5px 5px 150px 5px;
      };
   .right-image{
      border-radius:5px 5px 5px 150px;
   } 

   .text {
      width:60%;
   };
   
   img {
      width: 95%;            
      opacity:.9; 
   };
   h3 {
       
        text-transform:capitalize;
        color:#EEEEEE;
        opacity:.3;
      
   };
   @media screen and (max-width: 450px) {
      flex-direction: column;
      gap:20px;   
      width: 255px;
      .left-image{
            border-radius:5px;
         };
      .right-image{
         border-radius:5px;
      } 
      .text {
      width:240px;
   };
    
   };
`;

