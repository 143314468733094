import { SideBarContainer,Author,AuthorImage,Navigation,ILink } from "./sideBar.style";
import {  animateScroll as scroll } from 'react-scroll';


const SideBar = () => {
 const scrollToTop=()=> {
    scroll.scrollToTop();
  };

  const scrollToBottom = () => {
    scroll.scrollToBottom();
  };

  const scrollToSkill = () => {
    scroll.scrollTo(1100);
  };
  const scrollToMyWork = () => {
    scroll.scrollTo(2190);
  };



  return (
    <SideBarContainer>
        <AuthorImage src='./images/Mazen.jpg' alt="author image"/>
        <Author >
            <h3>mazen y. aldebeat</h3>
            <span>Frontend developer & 
              Graphic designer</span>
        </Author>
        <Navigation>
            <ILink to='' smooth={true}    onClick={scrollToTop}>About Me</ILink>
            <div className="hiding">
            <ILink className="skill" to='' smooth={true}   onClick={scrollToSkill}>What I’m good at</ILink>
            <ILink className="myWork" to='' smooth={true}  onClick={scrollToMyWork}>My Work</ILink>
            </div>
            <ILink to='' smooth={true}   onClick={scrollToBottom}>Contact</ILink>
        </Navigation>
       
    </SideBarContainer>
  )
};



// window.addEventListener( "scroll" ,() => {
//   console.log(window.scrollY);
// });

export default SideBar;