import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-scroll";

export const SideBarContainer = styled(Container)`

    width:400px;
    height:100vh;
    max-height: 100vh !important;
    margin-left: 10%;
    display: flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:center;
    background-color:rgb(14 131 136 / 20%);
    position: fixed ;
    @media screen and (max-width: 1550px) {
        margin-left: 9%;  
    };
    @media screen and (max-width: 1510px) {
        margin-left: 8%;  
    };  
    @media screen and (max-width: 1458px) {
        margin-left: 7%;  
    }; 
    @media screen and (max-width: 1410px) {
        margin-left:6%;  
    }; 
    @media screen and (max-width: 1360px) {
        margin-left:5%;  
    };
    @media screen and (max-width: 1314px) {
        margin-left:4%;  
    };
    @media screen and (max-width: 1270px) {
        margin-left:3%;  
    };
    @media screen and (max-width: 1238px) {
        margin-left:2%;  
    };
    @media screen and (max-width: 1196px) {
        margin-left:1%;  
    };
    @media screen and (max-width: 1162px) {
        margin-left:0%;  
    };
    @media screen and (max-width: 1080px) {        
    width:380px;
    @media screen and (max-width: 900px) {        
    width:250px;    
    };
    @media screen and (max-width: 450px) {        
    width:150px;    
    };  
  
    };
`;

export const AuthorImage = styled.img`
    margin-top: 15%;
    width: 50%;
    height: auto;
    border-radius: 50%;

    @media screen and (max-width: 1080px) {
        width: 55%;
    };
    @media screen and (max-width: 900px) {
        width: 70%;
    }; 
    @media screen and (max-width: 450px) {
        width: 90%;
    };       
`;

export const Author = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; 
    h3 {
        text-transform:capitalize;
        font-size: 30px;
        font-family: 'Shantell Sans',sans-serif;
        color:#EEEEEE; 
         
        @media screen and (max-width: 450px) {
            font-size: 25px;     
           };    

 
    };

    span {
        font-size: 14px;
        color:#EEEEEE;
        }

`;

export const Navigation = styled.div`
width : 400px;
margin-bottom: 50%;
 
    @media screen and (max-width: 1080px) {
    width :380px;
    .hiding{
        display: none !important;
    }  
    };    
    
    @media screen and (max-width: 900px) {
    width : 250px;
    };

    @media screen and (max-width: 450px) {
    width : 150px;
    };



`;

export const ILink = styled(Link)`
    /* width : 400px; */
    height: 60px;    
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2); 
    color: #fff;    
    font-size: 14px;
    border-bottom:2px solid #fff;
    transition: all 0.5s;
    cursor: pointer;
    margin: auto;    
    :hover {
    background-color: #fff;
    color: rgb(0 0 0 / 80%);  
}
`;