import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyA8KX8mLSe-b9HL7Vfu4gpG4QZ2J3yfD8c",
    authDomain: "contact-form-portfolio-5befd.firebaseapp.com",
    projectId: "contact-form-portfolio-5befd",
    storageBucket: "contact-form-portfolio-5befd.appspot.com",
    messagingSenderId: "980338906536",
    appId: "1:980338906536:web:ca24f76afe9c1e8873b99d"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  export const db = getFirestore(app);

