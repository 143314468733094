import styled from "styled-components";

export const SectionStyleContainer = styled.div`

    p{
    margin-top: 5px;
    font-size: 16px;
    color: #fff;
    letter-spacing: 0.5px;
    };
    .bar {
        height: 2px;
        width: 100%;
        background-color: white;
        margin:50px 0;
        opacity: 0.2 ;
    }
    @media screen and (max-width: 450px) {
        width: 60%;


    };

`;

